// Cache common DOM queries at the start for reuse
const domReadyFunctions = [
  OffcanvasMenu,
  UnderlineEffect,
  HighlightEffect,
  FilterEvents,
  FAQFilterHandler,
  MenuItemEvents,
  MarqueeEffect,
  ZoomInEffect,
  StretchMainContent,
  FlashingStarsEffect
];

// Use passive event listener for better scroll performance
document.addEventListener('DOMContentLoaded', () => {
  // Execute functions in a non-blocking way using requestAnimationFrame
  requestAnimationFrame(() => {
    domReadyFunctions.forEach(fn => fn());
  });
});

function StretchMainContent() {
  try {
    const main = document.querySelector('main');
    if (!main) return;
    
    const updateMainHeight = () => {
      const viewportHeight = window.innerHeight;
      const mainTop = main.offsetTop;
      const newHeight = viewportHeight - mainTop;
      main.style.minHeight = `${newHeight}px`;
      document.documentElement.style.setProperty('--main-content-height', `${newHeight}px`);
    };

    // Initial setup
    updateMainHeight();
    
    // Use debounced resize handler for better performance
    let resizeTimer;
    window.addEventListener('resize', () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(updateMainHeight, 100);
    }, { passive: true });
    
    // Cleanup
    window.addEventListener('pagehide', () => {
      clearTimeout(resizeTimer);
      window.removeEventListener('resize', updateMainHeight);
    }, { passive: true });

  } catch (error) {
    console.error(error);
  }
}

function OffcanvasMenu() {
  try {
    const menuItems = document.querySelectorAll('.offcanvas-menu .menu-item-has-children');
    if (!menuItems.length) return;
    
    menuItems.forEach(menuItem => {
      const link = menuItem.querySelector('a');
      const subMenu = menuItem.querySelector('.sub-menu--wrapper');
      if (!link || !subMenu) return;
      
      // Set initial state
      menuItem.setAttribute('aria-expanded', 'false');
      subMenu.hidden = true;

      // Handle link clicks
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const isExpanded = menuItem.getAttribute('aria-expanded') === 'true';
        
        // Toggle this menu item
        menuItem.setAttribute('aria-expanded', (!isExpanded).toString());
        subMenu.hidden = !isExpanded;

        // Close other open menus
        menuItems.forEach(otherItem => {
          if (otherItem !== menuItem && otherItem.getAttribute('aria-expanded') === 'true') {
            const otherSubMenu = otherItem.querySelector('.sub-menu--wrapper');
            otherItem.setAttribute('aria-expanded', 'false');
            if (otherSubMenu) otherSubMenu.hidden = true;
          }
        });
      });
    });

    // Close menus when clicking outside
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.offcanvas-menu')) {
        menuItems.forEach(menuItem => {
          const subMenu = menuItem.querySelector('.sub-menu--wrapper');
          if (subMenu) {
            menuItem.setAttribute('aria-expanded', 'false');
            subMenu.hidden = true;
          }
        });
      }
    });

  } catch (error) {
    console.error('OffcanvasMenu error:', error);
  }
}

function UnderlineEffect() {
  try {
    const elements = document.querySelectorAll('.is-style-underline');
    if (!elements.length) return;
    
    elements.forEach(el => {
      const text = el.textContent.trim();
      const lastSpaceIndex = text.lastIndexOf(' ');
      
      if (lastSpaceIndex === -1) {
        el.innerHTML = `<span>${text}</span>`;
      } else {
        const lastWord = text.substring(lastSpaceIndex + 1);
        const otherWords = text.substring(0, lastSpaceIndex);
        el.innerHTML = `${otherWords} <span>${lastWord}</span>`;
      }
    });
  } catch (error) {
    console.error(error);
  }
}

function HighlightEffect() {
  try {
    const elements = document.querySelectorAll('.is-style-highlight');
    if (!elements.length) return;
    
    elements.forEach(el => {
      const span = el.querySelector('span');
      if (span) {
        el.classList.remove('is-style-highlight');
        span.classList.add('is-style-highlight');
      }
    });
  } catch (error) {
    console.error(error);
  }
}

function FilterEvents() {
  try {
    // Select all filter buttons
    const filterButtons = document.querySelectorAll('.wp-block-button.is-style-filter');
    if (!filterButtons.length) return;
    
    // Set initial state - select "All" filter
    filterButtons.forEach(button => {
      const filterClass = [...button.classList].find(c => c.startsWith('filter_'));
      if (filterClass && filterClass.endsWith('_all')) {
        button.setAttribute('aria-checked', 'true');
      }
    });

    // Use event delegation for better performance
    const buttonContainer = filterButtons[0].parentElement;
    buttonContainer.addEventListener('click', (e) => {
      const button = e.target.closest('.wp-block-button.is-style-filter');
      if (!button) return;
      
      e.preventDefault();
      e.stopPropagation();

      const filterClass = [...button.classList].find(c => c.startsWith('filter_'));
      if (!filterClass) return;
      
      const [, filterId, filterTerm] = filterClass.split('_');
      const wasChecked = button.getAttribute('aria-checked') === 'true';
      
      // Update button states
      filterButtons.forEach(btn => {
        btn.setAttribute('aria-checked', 'false');
      });
      button.setAttribute('aria-checked', 'true');
      
      // Dispatch filter event
      document.dispatchEvent(new CustomEvent('filter', {
        detail: {
          filterId: filterId,
          filterTerm: filterTerm
        }
      }));
    });
  } catch (error) {
    console.error('FilterEvents error:', error);
  }
}

function FAQFilterHandler() {
  try {
    // Remove the page check since FAQs might be on different pages
    document.addEventListener('filter', (e) => {
      const { filterId, filterTerm } = e.detail;
      if (filterId !== 'faq') return;

      const accordions = document.querySelectorAll('.wp-block-custom-accordion');
      if (!accordions.length) return;
      
      const isAll = filterTerm === 'all';
      
      accordions.forEach(accordion => {
        if (isAll) {
          accordion.style.display = '';
        } else {
          const shouldShow = accordion.classList.contains(filterTerm);
          accordion.style.display = shouldShow ? '' : 'none';
        }
      });
    });
  } catch (error) {
    console.error('FAQFilterHandler error:', error);
  }
}

function MenuItemEvents() {
  try {
    const noLinkItems = document.querySelectorAll('.menu-item.no-link');
    if (!noLinkItems.length) return;
    
    noLinkItems.forEach(item => {
      const link = item.querySelector('a');
      if (!link) return;
      
      link.href = 'javascript:void(0)';
      link.addEventListener('click', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    });
  } catch (error) {
    console.error(error);
  }
}

function MarqueeEffect() {
  try {
    const marquees = document.querySelectorAll('.marquee');
    if (!marquees.length) return;
    
    // Cache colors array
    const colors = [
      '#A4CB65', '#1F3A34', '#CCFFD2', '#3C8DA5', '#062F47', '#CFEBFC',
      '#C34260', '#C70056', '#FFD9DA', '#7879B5', '#3D204B', '#E2D6FF'
    ];
    
    const intervals = new Set();

    marquees.forEach(marquee => {
      let currentColorIndex = 0;
      const spans = marquee.querySelectorAll('.marquee__content span');
      if (!spans.length) return;
      
      const updateColors = () => {
        currentColorIndex = (currentColorIndex + 1) % colors.length;
        const newColor = colors[currentColorIndex];
        
        // Use requestAnimationFrame for smoother color transitions
        requestAnimationFrame(() => {
          spans.forEach(span => {
            span.style.color = newColor;
          });
        });
      };

      updateColors();
      const interval = setInterval(updateColors, 3000);
      intervals.add(interval);
    });

    // Single cleanup listener
    window.addEventListener('pagehide', () => {
      intervals.forEach(interval => clearInterval(interval));
      intervals.clear();
    }, { passive: true });
  } catch (error) {
    console.error(error);
  }
}

function ZoomInEffect() {
  try {
    const zoomIns = document.querySelectorAll('.zoom-in');
    if (!zoomIns.length) return;

    // Use IntersectionObserver for better performance
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          requestAnimationFrame(() => {
            entry.target.classList.add('visible');
          });
          // Once the animation is done, we can stop observing the element
          observer.unobserve(entry.target);
        }
      });
    }, {
      // Start fading when element is 15% visible
      threshold: 0.15,
      rootMargin: '0px'
    });

    zoomIns.forEach(zoomIn => {
      observer.observe(zoomIn);
    });

    // Cleanup listener
    window.addEventListener('pagehide', () => {
      observer.disconnect();
    }, { passive: true });
    
  } catch (error) {
    console.error(error);
  }
}

function FlashingStarsEffect() {
  // Pre-define SVG strings to avoid string concatenation in loops
  const starA = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_330_762)"><path d="M11.7381 0.907131C8.521 5.41194 10.2836 6.60981 5.15843 6.60981C-3.11965 6.60981 -0.0223199 10.1991 4.64506 14.7296C3.64399 16.428 0.0161829 21.0654 2.7242 22.5242C2.83543 22.5841 2.94666 22.644 3.06645 22.6825C3.24613 22.7381 3.43437 22.7381 3.61832 22.7253C5.93276 22.5927 7.25469 20.6419 9.28677 19.8205C10.9681 19.1403 12.1873 20.2398 13.4921 21.2323C18.3905 24.9627 20.8761 23.6451 20.0718 17.7242C19.6312 14.4344 19.7082 14.1392 21.8258 12.9713C25.4836 10.7767 24.3114 7.34136 19.8493 7.34136C17.7274 7.34136 17.4365 7.12317 16.9959 4.78307C16.2643 0.68895 13.5606 -1.28752 11.7296 0.907131H11.7381Z" fill="#062F47"/></g><defs><clipPath id="clip0_330_762"><rect width="24" height="23.3283" fill="white"/></clipPath></defs></svg>`;
  const starB = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_330_742)"><path d="M8.3643 7.53013C7.41331 7.53013 0.183847 4.03549 0.0113746 5.98103C-0.264102 8.7048 4.55554 12.4882 4.55554 13.6776C4.55554 14.5708 -5.48139 22.4044 6.19403 19.0321C9.87823 17.9872 10.6975 18.2784 12.2952 21.2909C13.687 23.9682 14.1781 24.3451 15.3255 23.7602C16.1064 23.3613 16.0226 22.1646 16.0226 15.1019C17.6083 14.309 23.5443 11.8324 23.9443 10.4742C24.5264 8.49924 20.4853 8.88835 15.6944 8.74396C14.8488 3.41878 14.9781 0 13.3588 0C11.8784 0 8.9991 7.53013 8.3643 7.53013ZM17.249 11.546C14.4032 13.193 13.5648 13.6262 13.5648 16.1884C13.5648 18.7507 13.905 18.364 12.2545 16.9422C9.6818 14.703 5.55923 17.1943 6.85038 15.2683C9.03263 12.082 1.41032 7.79933 7.01327 9.83053C10.2855 10.9954 10.022 10.6259 12.5012 5.64821C13.5361 10.9342 12.9228 10.3591 17.2514 11.546H17.249Z" fill="#062F47"/></g><defs><clipPath id="clip0_330_742"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>`;

  try {
    const containers = document.querySelectorAll('.flashing-stars');
    if (!containers.length) return;
    
    // Add styles once instead of for each container
    const style = document.createElement('style');
    style.textContent = `
      .star-wrapper {
        position: absolute;
        z-index: 2;
        opacity: 0;
        transform: scale(0);
        transition: transform 2s ease-in-out, opacity 2s ease-in-out;
      }
      .star-wrapper.visible {
        opacity: 1;
        transform: scale(1);
      }
      @keyframes pulse {
        0%, 100% { transform: scale(1); opacity: 1; }
        50% { transform: scale(0.6); opacity: 0.6; }
      }
    `;
    document.head.appendChild(style);

    // Create a reusable function for finding safe positions
    const createPositionFinder = (container) => {
      const containerRect = container.getBoundingClientRect();
      
      // Get all child elements and their positions - do this once per container
      const allRects = [];
      
      // Get all child elements (not just text nodes)
      container.querySelectorAll('*').forEach(child => {
        // Skip the star wrappers themselves
        if (child.classList.contains('star-wrapper')) return;
        
        const childRect = child.getBoundingClientRect();
        allRects.push({
          left: childRect.left - containerRect.left,
          right: childRect.left - containerRect.left + childRect.width,
          top: childRect.top - containerRect.top,
          bottom: childRect.top - containerRect.top + childRect.height
        });
      });
      
      // Also get text nodes and their positions
      const walker = document.createTreeWalker(container, NodeFilter.SHOW_TEXT);
      let node;
      while (node = walker.nextNode()) {
        // Skip empty text nodes
        if (!node.textContent.trim()) continue;
        
        const range = document.createRange();
        range.selectNodeContents(node);
        Array.from(range.getClientRects()).forEach(rect => {
          allRects.push({
            left: rect.left - containerRect.left,
            right: rect.left - containerRect.left + rect.width,
            top: rect.top - containerRect.top,
            bottom: rect.top - containerRect.top + rect.height
          });
        });
      }
      
      // Cache container dimensions
      const width = containerRect.width - 24;
      const height = containerRect.height - 24;
      
      // Return a function that finds a safe position
      return () => {
        const isValidPosition = (x, y, padding) => {
          const starRect = {
            left: x - padding,
            right: x + 24 + padding,
            top: y - padding,
            bottom: y + 24 + padding
          };

          return !allRects.some(rect => 
            starRect.left < rect.right &&
            starRect.right > rect.left &&
            starRect.top < rect.bottom &&
            starRect.bottom > rect.top
          );
        };
        
        // Try to find a safe position with standard padding
        const maxAttempts = 50; // Reduced from 100 for better performance
        let attempts = 0;
        
        while (attempts < maxAttempts) {
          const x = Math.random() * width;
          const y = Math.random() * height;
          if (isValidPosition(x, y, 20)) {
            return { x, y };
          }
          attempts++;
        }

        // Try with smaller padding
        attempts = 0;
        while (attempts < maxAttempts) {
          const x = Math.random() * width;
          const y = Math.random() * height;
          if (isValidPosition(x, y, 10)) {
            return { x, y };
          }
          attempts++;
        }

        // Fallback position
        return { 
          x: Math.random() < 0.5 ? 5 : width - 29, 
          y: Math.random() < 0.5 ? 5 : height - 29 
        };
      };
    };

    containers.forEach(container => {
      // Ensure proper positioning context
      if (!['relative', 'absolute', 'fixed'].includes(getComputedStyle(container).position)) {
        container.style.position = 'relative';
      }

      const findSafePosition = createPositionFinder(container);
      
      // Create a reusable star animation function
      const createStar = (svg, delay = 0) => {
        // Create the wrapper element once
        const wrapper = document.createElement('div');
        wrapper.className = 'star-wrapper';
        wrapper.innerHTML = svg;
        container.appendChild(wrapper);
        
        // Animation function
        const animateStar = () => {
          const position = findSafePosition();
          
          // Batch DOM operations
          requestAnimationFrame(() => {
            wrapper.style.transition = 'none';
            wrapper.style.left = `${position.x}px`;
            wrapper.style.top = `${position.y}px`;
            
            // Force reflow
            wrapper.offsetHeight;
            
            wrapper.style.transition = 'transform 2s ease-in-out, opacity 2s ease-in-out';
            wrapper.classList.add('visible');
          });

          // Start pulsing after fade in
          const pulseTimeout = setTimeout(() => {
            wrapper.style.animation = 'pulse 3s ease-in-out 2';
            
            // When pulsing ends, fade out and reposition
            const onAnimationEnd = () => {
              wrapper.style.animation = '';
              wrapper.classList.remove('visible');
              
              // Wait for fade out, then restart cycle
              setTimeout(animateStar, 2000);
              wrapper.removeEventListener('animationend', onAnimationEnd);
            };
            
            wrapper.addEventListener('animationend', onAnimationEnd);
          }, 2000);
          
          return pulseTimeout;
        };

        // Start the animation cycle with initial delay
        const initialTimeout = setTimeout(animateStar, delay);
        
        return [initialTimeout]; // Return timeouts for cleanup
      };

      // Create two stars with offset timing
      const timeouts = [
        ...createStar(starA),
        ...createStar(starB, 3000)
      ];
      
      // Cleanup
      window.addEventListener('unload', () => {
        timeouts.forEach(timeout => clearTimeout(timeout));
      }, { passive: true });
    });

  } catch (error) {
    console.error('FlashingStarsEffect error:', error);
  }
}
